import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OrganizationApi from "../../../../api/organization-api";
import "./settings-page.scss";

const SettingsPage = () => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Get organization ID from Redux store
  const organizationId = useSelector(
    (state) => state?.userStore?.userInfo?._organizations?.[0] || ""
  );

  useEffect(() => {
    const fetchOrganizationSettings = async () => {
      setLoading(true);
      try {
        if (!organizationId) return;

        const response = await OrganizationApi.private.fetchOrganization(organizationId);
        if (response && response.data.data) {
          const { communicationWindowLimits } = response.data.data;
          if (communicationWindowLimits && communicationWindowLimits.length > 0) {
            const limit = communicationWindowLimits[0];
            setStartTime(limit.startTime);
            setEndTime(limit.endTime);
            setSelectedDays(limit.days);
            setIsFeatureEnabled(true);
          }
        }
      } catch (error) {
        console.error("Error fetching organization settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationSettings();
  }, [organizationId]);

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}:00`;
  };

  const handleDayToggle = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = isFeatureEnabled
      ? {
          communicationWindowLimits: [
            {
              startTime: formatTime(startTime),
              endTime: formatTime(endTime),
              days: selectedDays,
              timezone: "Europe/London", // Replace with dynamic timezone if needed
            },
          ],
        }
      : { communicationWindowLimits: [] };

    try {
      if (!organizationId) {
        console.error("Organization ID not found.");
        return;
      }

      await OrganizationApi.private.updateOrganization(organizationId, payload);
      console.log("Settings updated successfully:", payload);
      setSuccessMessage("Settings updated successfully.");
      setTimeout(() => setSuccessMessage(""), 5000);
    } catch (error) {
      console.error("Error updating settings:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="m-0">        Communication Window Settings
      </h1>

      <div className="formWrapper">
        {successMessage && <div className="alert alert-success">{successMessage}</div>}

        <div className="form-check mb-3">
          <input
            type="checkbox"
            id="enableFeature"
            className="form-check-input"
            checked={isFeatureEnabled}
            onChange={(e) => setIsFeatureEnabled(e.target.checked)}
          />
          <label htmlFor="enableFeature" className="form-check-label">
            Enable Communication Window Feature
          </label>
        </div>

        {isFeatureEnabled && (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="startTime">Start Time</label>
                  <input
                    type="time"
                    id="startTime"
                    className="form-control"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="endTime">End Time</label>
                  <input
                    type="time"
                    id="endTime"
                    className="form-control"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Select Days</label>
              <div className="weekdays">
                {weekdays.map((day) => (
                  <div key={day} className="form-check">
                    <input
                      type="checkbox"
                      id={day}
                      className="form-check-input"
                      checked={selectedDays.includes(day.toLocaleLowerCase())}
                  onChange={() => handleDayToggle(day.toLocaleLowerCase())}
                    />
                    <label htmlFor={day} className="form-check-label">
                      {day}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <div className="buttons chat-new-start mt-4">
          <button className="btn btn-success px-4" onClick={handleSubmit} disabled={loading}>
            {
              loading ? "Saving" : "Save"
            }
          </button>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
