// organization-api.js
import { ProtectedHttpClient } from "../shared/clients/http-client";

const OrganizationApi = {
  private: {
    addNewUser: (payload) => ProtectedHttpClient.post("/organization/invite", payload), // Requires token
    getAllUsers: () => ProtectedHttpClient.post("/users"), // Requires token
    fetchOrganization: (organizationId) =>
      ProtectedHttpClient.get(`/organization/${organizationId}`), // Requires token
    updateOrganization: (organizationId, payload) =>
      ProtectedHttpClient.patch(`/organization/${organizationId}`, payload), // Requires token
  },
};

export default OrganizationApi;
